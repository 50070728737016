/* src/styles/styles.css */

/* Custom Styles */
body {
    font-family: 'Inter', sans-serif;
  }
  
  .navbar-brand {
    font-weight: 900;
    font-size: 1.5rem;
  }
  
  .navbar-links a {
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #FFA500; /* Custom hover color */
  }
  