/* src/styles/Navbar.css */

.navbar {
    background-color: #0b080d; /* Tailwind's gray-900 */
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    font-size: 2rem;
    font-weight: 900;
    color: white;
  }
  
  .navbar-brand span {
    color: #5303B8; /* Custom orange color */
  }
  
  .navbar-links {
    display: flex;
    gap: 3rem;
    list-style-type: none;  /* Remove bullet points */
    padding: 0;  /* Remove default padding */
    margin: 0;  /* Remove default margin */
  }
  
  .navbar-links a {
    color: white;
    font-size: 1.125rem;
    text-decoration: none;  /* Remove underline */
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #5303B8; /* Custom hover color */
  }
  