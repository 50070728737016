/* src/styles/Footer.css */

.footer {
    background-color: #0b080d; /* Tailwind's gray-900 */
    padding: 1.5rem 1rem;
  }
  
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .footer-container a {
    color: #9ca3af; /* Tailwind's gray-400 */
    transition: color 0.3s;
  }
  
  .footer-container a:hover {
    color: #ffffff; /* White */
  }
  
  .footer-container .icon {
    width: 1.25rem; /* 20px */
    height: 1.25rem; /* 20px */
  }
  .tech-stack {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: white;
  }