/* src/components/Home.css */
/* Reset default styles */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  
  body {
    background-color: #1a202c; /* Tailwind's gray-900 */
  }

.home {
  min-height: 100vh; /* Changed from height to min-height */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: #1a202c; /* Tailwind's gray-900 */
  padding: 4rem;
  gap: 2.5rem;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}
  
  .home h1 {
    font-size: 4.5rem;
    font-weight: 900;
    color: white;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  
  .home h1 span {
    color: #5303B8; 
  }
  
  .home h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
  }
  
  .home p {
    color: white;
    max-width: 48rem;
    font-size: 1.125rem;
  }
  
  